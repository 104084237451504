<template>
  <b-row v-if="getFormData.id">
    <b-col
      v-if="getFormData.status !== '1'"
      cols="12"
    >
      <b-card>
        <b-card-title>Duyuru Gönder</b-card-title>
        <b-form-group label="Konu">
          <b-form-input
            v-model="getFormData.type"
            placeholder="Konu"
          />
        </b-form-group>
        <b-form-group label="Gönderilecek E-Posta Adresleri">
          <b-form-input
            v-model="getFormData.mailto"
            placeholder="Gönderilecek E-Posta Adresleri"
          />
        </b-form-group>
        <b-alert
          show
          variant="primary"
        >
          <p class="alert-body">
            Gönderilecek e-posta adreslerini aralarına virgül koyarak yazınız.
          </p>
        </b-alert>
        <b-button
          v-if="!submitStatus"
          variant="primary"
          @click="submitForm"
        >
          Gönder
        </b-button>
        <b-button
          v-else
          variant="primary"
          disabled
        >
          <b-spinner small />
          Lütfen Bekleyiniz...
        </b-button>
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card>
        <b-card-title>Duyuru Önizleme</b-card-title>
        <b-alert
          v-if="getFormData.status === '1'"
          variant="primary"
          show
        >
          <div class="alert-body text-center">
            <span><strong>{{ this.moment(getFormData.modified).format('DD.MM.YYYY') }}</strong> tarihinde {{ getFormData.username }} tarafından gönderildi.</span>
          </div>
        </b-alert>
        <WelcomePreview v-if="getFormData.id_com_hr_notification_types === '1'" />
        <BabyPreview v-if="getFormData.id_com_hr_notification_types === '2'" />
        <LeavingPreview v-if="getFormData.id_com_hr_notification_types === '3'" />
        <PromotePreview v-if="getFormData.id_com_hr_notification_types === '4'" />
        <DeathPreview v-if="getFormData.id_com_hr_notification_types === '5'" />
        <SeniorityPreview v-if="getFormData.id_com_hr_notification_types === '6'" />
        <assigment-preview v-if="getFormData.id_com_hr_notification_types === '7'" />
      </b-card>
    </b-col>
  </b-row>
  <b-row v-else>
    <b-col cols="12">
      <Loading />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BCard, BCardTitle, BFormGroup, BFormInput, BSpinner, BAlert,
} from 'bootstrap-vue'
import html2canvas from 'html2canvas'
// eslint-disable-next-line import/extensions
import Loading from '@/layouts/components/Loading'
// eslint-disable-next-line import/extensions
import WelcomePreview from '@/views/Hr/Notifications/Preview/Welcome'
// eslint-disable-next-line import/extensions
import BabyPreview from '@/views/Hr/Notifications/Preview/Baby'
// eslint-disable-next-line import/extensions
import LeavingPreview from '@/views/Hr/Notifications/Preview/Leaving'
// eslint-disable-next-line import/extensions
import PromotePreview from '@/views/Hr/Notifications/Preview/Promote'
// eslint-disable-next-line import/extensions
import DeathPreview from '@/views/Hr/Notifications/Preview/Death'
// eslint-disable-next-line import/extensions
import SeniorityPreview from '@/views/Hr/Notifications/Preview/Seniority'
import AssigmentPreview from '@/views/Hr/Notifications/Preview/Assignment.vue'

export default {
  name: 'Edit',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BSpinner,
    BAlert,
    BButton,
    BCard,
    BCardTitle,
    Loading,
    WelcomePreview,
    BabyPreview,
    LeavingPreview,
    PromotePreview,
    DeathPreview,
    SeniorityPreview,
    AssigmentPreview,
  },
  data() {
    return {
      submitStatus: false,
      canvasImage: null,
      canvasStatus: false,
    }
  },
  computed: {
    saveStatus() {
      return this.$store.getters['hrNotifications/getHr_notificationSaveStatus']
    },
    getFormData() {
      return this.$store.getters['hrNotifications/getHr_notification']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    createCanvas() {
      html2canvas(document.getElementById('preview'), {
        allowTaint: true, useCORS: true,
      }).then(canvas => {
        this.getFormData.canvasImage = canvas.toDataURL('image/jpg', 0.7)
        this.$store.dispatch('hrNotifications/hr_notificationSend', this.getFormData)
      })
    },
    getData() {
      this.$store.dispatch('hrNotifications/hr_notificationView', this.$route.params.id)
    },
    submitForm() {
      this.submitStatus = true
      this.createCanvas()
    },
  },
}
</script>
